export const OrgInvoiceConfig = {
  api: {},
  events: {
    name: 'org-invoice',
    // refresh-index-data
    refresh: `rid-org-invoice`,
    // slideover-right
    sorId: 'org-invoice',
    sorOpen: 'sor-open-org-invoice',
    sorClose: 'sor-close-org-invoice',
    sorToggle: 'sor-toggle-org-invoice',
    // editing-data
    editingData: 'edit-org-invoice-data',
    // viewing-data
    viewingData: 'view-org-invoice-data',
  },
}
